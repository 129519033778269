import React from 'react';
import { last, map, pathOr } from 'ramda';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { StickyShareButtons } from 'sharethis-reactjs';

import ArticleItem from '../components/ArticleItem';
import ArticleLargeItem from '../components/ArticleLargeItem';
import BlogCTA from '../components/BlogCTA';
import Layout from '../components/layout';

export default function Template({ data, pageContext }) {
    const { blogCategories } = pageContext;
    const { t } = useTranslation('blog');
    const blogCategory = last(blogCategories);
    const [latestArticleData, ...articles] = pathOr([], ['allMarkdownRemark', 'edges'], data);
    const latestArticle = {
        title: pathOr('', ['node', 'frontmatter', 'title'], latestArticleData),
        author: pathOr('', ['node', 'frontmatter', 'author'], latestArticleData),
        categories: pathOr('', ['node', 'frontmatter', 'categories'], latestArticleData),
        image: getImage(pathOr('', ['node', 'frontmatter', 'coverImage'], latestArticleData)),
        date: pathOr('', ['node', 'frontmatter', 'date'], latestArticleData),
        path: pathOr('', ['node', 'frontmatter', 'path'], latestArticleData)
    };

    return (
        <Layout>
            <section className="bg-white pt-4 pb-24 flex flex-col justify-center items-center">
                <div className="flex flex-col sm:flex-row mx-auto items-start justify-center mt-20 sm:mt-28">
                    <div className="container max-w-5xl sm:my-8 sm:mt-0 sm:mr-8">
                        <div className="w-full text-center flex flex-col items-center justify-center">
                            <h4 className="w-full mb-2 text-xl font-bold leading-tight text-black px-4 sm:px-0">
                                {t(`category.${blogCategory}`)}
                            </h4>
                            <h1 className="w-full mb-2 text-4xl font-bold leading-tight text-black mt-2 px-4 sm:px-0">
                                {t('subtitle')}
                            </h1>
                            <div className="text-md text-gray-800 my-2 mb-8">{t('description')}</div>
                        </div>
                        {latestArticle && <ArticleLargeItem article={latestArticle} />}
                        <div className="grid gap-6 grid-cols-1 sm:grid-cols-3 mt-6 pb-6 px-4 sm:px-0">
                            {map(
                                (article) => (
                                    <ArticleItem article={pathOr({}, ['node', 'frontmatter'], article)} />
                                ),
                                articles
                            )}
                        </div>
                    </div>
                    <BlogCTA />
                </div>
            </section>
            <StickyShareButtons
                config={{
                    alignment: 'left',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    hide_desktop: false,
                    labels: 'counts',
                    language: 'fr',
                    min_count: 0,
                    networks: ['messenger', 'email', 'sharethis', 'facebook', 'whatsapp'],
                    padding: 12,
                    radius: 4,
                    show_total: true,
                    show_mobile: true,
                    show_toggle: true,
                    size: 48,
                    top: 160
                }}
            />
        </Layout>
    );
}

export const pageQuery = graphql`
    query BlogPostByPath($language: String!, $blogCategories: [String]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: $language }, categories: { in: $blogCategories } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        author
                        categories
                        date
                        coverImage {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
            }
        }
    }
`;
